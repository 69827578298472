.App {
  text-align: center;
  background: linear-gradient(135deg, #f7f8fa, #e6e9f0);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f7f8fa, #e6e9f0);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.card {
  background: linear-gradient(135deg, #ffffff, #f2f2f2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin: 1rem;
  border-radius: 30px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-title {
  font-size: 1.5rem;
  color: #0078ff;
  margin-bottom: 0.5rem;
}

.card-text {
  font-size: 1rem;
  color: #333;
  line-height: 1.5;
}

.footer {
  margin-top: auto;
  padding: 1rem;
  background: linear-gradient(135deg, #e6e9f0);
  color: #333;
  text-align: center;
  border-radius: 100;
}

/* Welcome.css */

.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f7f8fa, #e6e9f0);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.welcome-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  animation: fadeIn 1s ease-in-out;
}

.welcome-logo {
  height: 35vmin;
  pointer-events: none;
  margin-top: 10px;
}

.welcome-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 0.5rem;
  background-image: linear-gradient(45deg, #0078ff, #00c6ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 800;
}

.welcome-title-stats {
  font-size: 2.5rem;
  color: #333;
  margin-top: -30px;
  margin-left: 370px;
  background-image: linear-gradient(45deg, #0078ff, #00c6ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-weight: 800;
}

.welcome-title-stats-saved {
  font-size: 2rem;
  color: #333;
  margin-top: -30px;
  margin-left: 30px;
  background-image: linear-gradient(45deg, #0078ff, #00c6ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-weight: 800;
}

.welcome-subtitle {
  font-size: 1.2rem;
  color: #7c7b7b;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px 20px;
  border-radius: 20px;
}

.subscribe-button {
  font-size: 1.2em;
  padding: 10px 20px;
  background-color: #61dafb;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.subscribe-button:hover {
  background-color: #21a1f1;
}

.welcome-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;
  animation: slideUp 1s ease-in-out;
}

.feature-card {
  background: linear-gradient(135deg, #ffffff, #f2f2f2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin: 1rem;
  max-width: 300px;
  text-align: center;
  border-radius: 30px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feature-title {
  font-size: 1.5rem;
  color: #0078ff;
  margin-bottom: 0.5rem;
}

.feature-description {
  font-size: 1rem;
  color: #333;
  line-height: 1.5;
}

.welcome-footer {
  margin-top: auto;
  padding: 1rem;
  background-color: #e6e6e6;
  color: #333;
  text-align: center;
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.App-logo {
  height: 35vmin;
  pointer-events: none;
  margin-top: 10px;
}

.App-header {
  color: #5d95ff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.button-css {
  color: #61dafb;
  margin-bottom: 100px !important;
  margin-top: 100px !important;
}

/* Preview Box */
.Preview-Box {
  background-color: #f8f8f8;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  width: 80%;
  max-width: 1200px;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 20px;
  opacity: 0; /* set initial opacity to 50% */
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  transition: opacity 0.5s ease-in-out; /* add a transition effect to the opacity property */
}

.Preview-Box.show {
  opacity: 1; /* set opacity to 100% when the 'show' class is added */
}

/* Preview Text */
.Preview-Text {
  color: #333333;
  font-size: 20px;
  display: flex;
  margin: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

/* Preview Title */
.Preview-Title {
  color: #333;
  font-size: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 15px;
  margin-top: 5px;
}

/* Preview Box Text */
.Preview-Box-Text {
  color: #333333;
  font-size: 20px;
  display: flex;
  margin: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

/* Preview Box Title */
.Preview-Box-Title {
  color: #0078ff;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 10px;
  margin-top: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
